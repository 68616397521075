/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

//
//  SharedCatalog page styles
//  _____________________________________________

@import 'module/_configure-modal.scss';
@import 'module/_shared-catalog-configuration.scss';

.configure-pricing-custom-price {
    .admin__field-control {
        display: inline-flex;
        flex-direction: row;

        .currency-addon {
            margin-left: $space-base;
        }
    }
}

.configure-column-field-tier-price {
    white-space: nowrap;

    a {
        cursor: pointer;
    }
}
