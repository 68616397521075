//
//  Navigation variables
//  _____________________________________________

$navigation__background                         : $c-white;
$navigation__indent-side                        : 16px;
$navigation-level0-item__color                  : $c-library-gray-7;
$navigation-level0-item__active__color          : $c-library-gray-9;
$navigation-level0-item__font-size              : 15;
$navigation-level0-item__font-weight            : 400;
$navigation-level0-item__active__border-width   : 0;
$navigation-level0-item__text-transform         : none;
$navigation-level0-item__border                 : 1px solid $c-border;

$navigation-desktop__font-weight                : 400;
$navigation-desktop__border                     : none;
$navigation-desktop__background                 : $c-blue-base;

$navigation-desktop-level0-item__padding        : 0;
$navigation-desktop-level0-item__color          : $c-white;
$navigation-desktop-level0-item__line-height    : 38px;
$navigation-desktop-level0-item__hover__color   : $c-white;
$navigation-desktop-level0-item__active__color  : $c-white;

$submenu__font-weight                           : 400;
$submenu__padding-left                          : 16px;

$submenu-desktop__font-weight                   : 500;
$submenu-desktop-item__color                    : $c-text-base;
$submenu-desktop-item__hover__color             : $c-white;
$submenu-desktop-item__hover__background        : $c-blue-base;

$active-nav-indent                              : 40px;

//________________________________________________________________________

$navigation-level0-item__text-decoration                 : none !default;

$navigation-level0-item__active__background              : '' !default;
$navigation-level0-item__active__text-decoration         : '' !default;

$submenu__background                                     : '' !default;
$submenu__font-size                                      : '' !default;

$submenu-item__background                                : '' !default;
$submenu-item__border                                    : '' !default;
$submenu-item__color                                     : $c-gray-slate !default;
$submenu-item__text-decoration                           : '' !default;

$submenu-item__active__background                        : '' !default;
$submenu-item__active__color                             : '' !default;
$submenu-item__active__text-decoration                   : '' !default;

//
//  Desktop navigation
//  ---------------------------------------------

$navigation-desktop__font-size                           : '' !default;
$navigation-desktop-level0-item__margin                  : 0 $space-base 0 0 !default;

$navigation-desktop-level0-item__background              : '' !default;
$navigation-desktop-level0-item__border                  : '' !default;
$navigation-desktop-level0-item__text-decoration         : none !default;

$navigation-desktop-level0-item__hover__background       : '' !default;
$navigation-desktop-level0-item__hover__border           : '' !default;
$navigation-desktop-level0-item__hover__text-decoration  : $navigation-desktop-level0-item__text-decoration !default;

$navigation-desktop-level0-item__active__background      : '' !default;
$navigation-desktop-level0-item__active__color           : $navigation-desktop-level0-item__hover__color !default;
$navigation-desktop-level0-item__active__text-decoration : $navigation-desktop-level0-item__text-decoration !default;

$submenu-desktop__background                             : $c-white;
$submenu-desktop__border-width                           : 1px !default;
$submenu-desktop__border-style                           : solid !default;
$submenu-desktop__border-color                           : $c-border !default;
$submenu-desktop__box-shadow                             : 0 $space-xs $space-xs black(0.19) !default;
$submenu-desktop__font-size                              : '' !default;
$submenu-desktop__font-weight                            : $font-weight-bold !default;
$submenu-desktop__min-width                              : 230px !default;
$submenu-desktop__padding                                : 15px 0 !default;

$submenu-desktop-arrow                                   : true !default; // [true|false]
$submenu-desktop-arrow__size                             : 10px !default;
$submenu-desktop-arrow__left                             : 20px !default;

$submenu-desktop-item__padding                           : 8px $space-l !default;
$submenu-desktop-item__background                        : '' !default;
$submenu-desktop-item__border                            : '' !default;
$submenu-desktop-item__color                             : $navigation-desktop-level0-item__color !default;
$submenu-desktop-item__text-decoration                   : '' !default;

$submenu-desktop-item__hover__border                     : '' !default;
$submenu-desktop-item__hover__color                      : $navigation-desktop-level0-item__hover__color !default;
$submenu-desktop-item__hover__text-decoration            : $navigation-desktop-level0-item__text-decoration !default;
//
$submenu-desktop-item__active__background                : '' !default;
$submenu-desktop-item__active__color                     : '' !default;
$submenu-desktop-item__active__text-decoration           : '' !default;