/**
* Gemaire Distributing
*/


// ==============================================
// Global
// ==============================================

body {
    background: #FFF;
}

// ----------------------------------------------

@media (min-width: $bp-screen-m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

}


// ==============================================
// Page Wrapper
// ==============================================

.page-wrapper {
    min-width: $bp-screen-xxs;
}

@media (min-width: $bp-screen-m) {

    .page-wrapper {
        display: flex;
        flex-direction: column;
        min-height: 100%; // Stretch content area for sticky footer
    }

    .page-main {
        flex: 1 0 auto;
    }

    .ie10 .page-wrapper,
    .ie11 .page-wrapper {
        height: 100%;
    }

    .page-wrapper>.breadcrumbs,
    .page-wrapper>.top-container,
    .page-wrapper>.widget {
        width: 100%;
    }

}

// ==============================================
// List
// ==============================================
.list {
    list-style: disc outside none;
    margin-left: 16px;
}


// ==============================================
// Trim
// ==============================================
.block.category.event,
.page-main,
.page-wrapper>.breadcrumbs,
.page-wrapper>.page.messages,
.page-wrapper>.widget,
.top-container {
    padding-left: $layout-indent__width;
    padding-right: $layout-indent__width;
}

// ==============================================
// Checkout Restricted
// ==============================================
.customer-checkout-restricted {

    .tocart,
    button.checkout,
    button.viewcart,
    .requisition-header,
    .requisition-footer,
    [data-action="add-to-cart"] {
        display: none;
    }
}


// Live chat button

@media (max-width: 768px) {
    .embeddedServiceHelpButton .helpButton .uiButton {
        min-width: 0;

        .embeddedServiceIcon {
            margin-right: 0;
        }

        .helpButtonLabel {
            display: none;
        }
    }
}

.highlighted {
    background: #ecda00;
}
