/**
* Gemaire Distributing
*/

// ==============================================
// Current Store
// ==============================================

.header-store {
    position: absolute;
    right: 141px;
    top: 27px;
    width: 233px;
    color: $c-text-contrast-base;
}

.header-store_wrapper {
    margin: 0 0 0 auto;
    background: black(0.1);
    color: #fff;
    padding: 8px;
    min-height: 50px;
    position: relative;
    width: 100%;
    border: none;
    padding-right: 32px;
    text-align: left;
}

button.header-store_wrapper {
    cursor: pointer;
}

button.header-store_wrapper {
    @include lib-icon-font(
        $_icon-font-content: $icon-down,
        $_icon-font-size: 22px,
        $_icon-font-position: after,
        $_icon-font-display: block
    );

    &:after {
        position: absolute;
        right: 10px;
        top: calc(50% - 10px);
    }
}

button.header-store_wrapper:hover {
    background: black(0.15);
}

@media (max-width: $bp-header-alpha - 1px) {
    .header-store {
        margin: 0 0 0 20px;
        width: auto;
        float: right;
        position: static;
    }

    button.header-store_wrapper {
        background: transparent;
        padding: 0;

        &:after {
            display: none;
        }
    }

    button.header-store_wrapper {
        &:hover {
            background: transparent;
        }
    }
}



@media (max-width: 349px) {
    .header-store {
        margin-left: 5px;
    }
}

// ==============================================
// Current Store - Container
// ==============================================

.header-store_container {
    width: auto;
    display: block;
    background: transparent;
    border: none;
    vertical-align: top;
}

// ==============================================
// Current Store - Toggle
// ==============================================

.header-store_toggle {
    width: 100%;
    position: relative;
    padding: 0 5px 0 35px;

    @include lib-icon-font(
        $_icon-font-content: $icon-location,
        $_icon-font-size: 36px,
        $_icon-font-line-height: 36px,
        $_icon-font-text-hide: false,
        $_icon-font-position: before,
        $_icon-font-display: block,
        $_icon-font-color: $c-white
    );
}

.header-store_toggle:after {
    margin-top: 5px;
}

.header-store_toggle p {
    margin: 0;
    font-weight: 400;
    color: $c-white;
    font-size: $font-size-xxs;

    &.small {
        color: white(0.8);
        font-size: $font-size-xxxxs;
        line-height: 1;
        padding-top: 3px;
        text-transform: uppercase;
    }
}

@media (max-width: $bp-header-alpha - 1px) {
    .header-store_toggle p {
        font-size: 16px;

        &.small {
            display: none;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .header-store_toggle p {
        display: none;
    }
}

.header-store_toggle::before {
    color: #ffffff;
    position: absolute;
    left: -3px;
    top: -2px;
}

.header-store_toggle::after {
    position: absolute;
    right: -15px;
    top: 8px;
}

@media (max-width: $bp-header-alpha - 1px) {
    .header-store_toggle {
        &::before {
            left: 5px;
            top: 0;
        }

        &::after {
            display: none !important;
        }
    }
}

// ==============================================
// Current Store - Dropdown
// ==============================================

.header-store_dropdown {
    display: none;
    padding: 20px;
    color: $c-text-base;
    font-size: 15px;
}

@media (max-width: $bp-header-alpha - 1px) {
    .header-store_dropdown {
        right: 0;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .header-store_wrapper .dropdown {
        position: static;
    }

    .header-store_wrapper .dropdown-content {
        width: 100%;
        left: 0;
        right: 0;
        top: 44px;
    }
}

.header-store_container.active .header-store_dropdown {
    @include menu;
    display: block;
    top: 49px;
}

.header-store_dropdown::before {
    @include triangle($direction: up, $size: 8px, $color: $c-border);
    top: -9px;
    right: $space-xl;
}

.header-store_dropdown::after {
    @include triangle($direction: up, $size: 8px, $color: $c-white);
    top: -8px;
    right: $space-xl;
}

@media (max-width: $bp-header-alpha - 1px) {
    .header-store_toggle {
        width: 100%;
        position: relative;
        margin: 0;
        padding-right: 0;
        line-height: 67px;
        height: 67px;

        @include lib-icon-font(
            $_icon-font-content: $icon-location,
            $_icon-font-size: 25px,
            $_icon-font-line-height: 67px,
            $_icon-font-text-hide: false,
            $_icon-font-position: before,
            $_icon-font-display: block,
            $_icon-font-color: $c-white
        );
    }

    .header-store_container {
        &.active .header-store_dropdown {
            top: 60px;
        }
    }
}

// ==============================================
// Current Store - Name
// ==============================================

@media (min-width: $bp-header-alpha) {
    .store-name {
        white-space: nowrap;
    }
}

// ==============================================
// Current Store - Hours
// ==============================================

.store-hours {
    padding: 10px 0;
}

.store-hours .open {
    color: $c-green;
}

.store-hours .closed {
    color: $c-red-base;
}

// ==============================================
// Current Store - Distance
// ==============================================

.store-distance-wrapper {
    padding-bottom: $space-base;

    .store-distance {
        display: inline;
    }
}

// ==============================================
// Current Store - Address
// ==============================================

.store-address {
    padding: 10px 0 0;
}

// ==============================================
// Current Store - Branch Name
// ==============================================

@media (max-width: $bp-screen-m - 1px) {
    .branch_name {
        display: none;
    }
}
