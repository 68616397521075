/**
* Gemaire Distributing
*/

// ==============================================
// eJSIS
// ==============================================

@media (max-width: $bp-screen-m - 1px) {

    .ejsis-container {
        display: flex;

        .ejsis-steps {
            flex: 1 1 35%;
        }

        .ejsis-forms {
            flex: 1 1 65%;

            > .grid {
                display: flex;
                flex-direction: column;
            }

            .ejsis-diagram-container {
                order: -1;
            }
        }

        .cards_item--order_info {
            display: flex;
            align-items: center;

            h3 {
                flex: 1 0 65%;
                padding-right: 15px;
                margin-bottom: 0;
            }
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {

    .ejsis-container {

        .field {
            width: 100%;

            .m-t-0 {
                margin-top: 18px !important;
            }
        }

        .field-row {
            flex-wrap: wrap;           
        }

        .ejsis-steps {
            display: none;
        }        
    }
    
    .ejsis-react-app {
        .action-group {
            justify-content: space-between;
        }
    }
}