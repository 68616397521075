/**
 * Copyright © Magento, Inc. All rights reserved.
 * See COPYING.txt for license details.
 */

.shared-catalog-config-container {
    @extend .abs-clearfix !optional;

    .config-description {
        float: left;
        margin-bottom: 3rem;
        margin-top: .6rem;
    }

    .config-button {
        float: right;
    }
}

.shared-catalog-configure-state {
    float: left;
    width: 80%;

    .action {
        background: none;
        border: 0;
        color: $c-blue-base;
        margin: 0 2.5rem 1rem 0;
        padding: 0;
        position: relative;

        &:hover {
            color: $c-blue-base;
        }
    }
}

.jstree-shared-catalog-state {
    ul {
        li {
            a.jstree-anchor {
                padding-left: 7px;
            }
        }
    }
}

.shared-catalog-config-store-switcher {
    margin-bottom: 3rem;
}

.modal-container {
    display: none;
}
