/**
* Gemaire Distributing
*/


// ==============================================
// Actions Dropdown
// ==============================================

.actions-dropdown {
    position: relative;
    display: flex !important;
    font-size: 14px;

    &.opened {
        .actions-dropdown-menu {
            display: block;
        }

        .actions-dropdown-toggle {
            @include lib-icon-font(
                $_icon-font-content:      $icon-arrow-dropup,
                $_icon-font-size:         20px,
                $_icon-font-line-height:  20px,
                $_icon-font-color:        $c-blue-bright,
                $_icon-font-margin:       0,
                $_icon-font-position:     'after'
            )
        }
    }

    &-divider {
        border-top: 1px solid $c-border;
    }

    .actions-dropdown-menu {
        width: calc(100% + 20px);
        right: 0;
        padding: 0;
        top: 36px;
        border: 0;
        background: white;
        box-shadow: $box-shadow-base;
        z-index: 1;

        .text-link {
            padding: 8px 20px;
        }

        &.no-main {
            .text-link {
                white-space: nowrap;
            }
        }
    }

    &-toggle {
        margin-left: -5px;
        width: 36px;
        display: inline-block;
        text-decoration: none;
        vertical-align: top;

        @include lib-icon-font(
            $_icon-font-content:      $icon-arrow-dropdown,
            $_icon-font-size:         20px,
            $_icon-font-line-height:  20px,
            $_icon-font-color:        $c-blue-bright,
            $_icon-font-margin:       0,
            $_icon-font-position:     'after'
        )
    }

    .external-link {
        > span {
            @include lib-icon-font(
                $_icon-font-content:      $icon-open-new,
                $_icon-font-size:         $account-nav-icon-size - 8px,
                $_icon-font-line-height:  $account-nav-icon-size - 8px,
                $_icon-font-color:        $c-blue-bright,
                $_icon-font-color-hover:  $c-blue-bright,
                $_icon-font-color-active: $c-blue-bright,
                $_icon-font-margin:       -3px 5px 0,
                $_icon-font-position:     'after'
            )
        }
    }
}
