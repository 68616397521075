/**
* Gemaire Distributing
*/

// ==============================================
// Var
// ==============================================

$minicart-padding: $space-base;

$minicart-quantity-height: 24px;

// ==============================================
// Minicart
// ==============================================

.block-minicart a:not(.button) {
    color: $c-link-base;

    &:hover {
        color: darken($c-blue-base, 10%);
    }
}

.block-minicart .items-total {
    float: left;
}

.block-minicart .items-total .count {
    font-weight: $font-weight-bold;
}

.block-minicart .subtotal {
    font-size: 20px;
    display: inline-flex;
}

.block-minicart .subtotal .price-container {
    margin-left: 10px;
}

.block-minicart .amount {
    display: inline-block;
}

.block-minicart .amount .price-wrapper:first-child .price {
    font-weight: $font-weight-bold;
}

.block-minicart .subtitle.empty {
    display: block;
    font-size: 14px;
    padding: $space-xxl 0 $space-l;
    text-align: center;
}

.block-minicart .text.empty {
    text-align: center;
}

.minicart-actions > * {
    margin-bottom: $space-xs;
}

.minicart-actions_extra:after {
    @include clearfix;
}

@media (min-width: $bp-screen-m) {
    .minicart-actions {
        display: flex;
        justify-content: space-between;

        .button {
            width: 48%;
        }
    }
}

@media (max-width: $bp-screen-m - 1px) {
    .minicart-actions_extra {
        width: 100%;
    }
    .minicart-actions_extra > * {
        margin-bottom: $space-xxs;
    }
}

.block-minicart .block-content > .actions > .primary {
    margin: 0 $space-base $space-base;
}

.block-minicart .block-category-link,
.block-minicart .block-product-link,
.block-minicart .block-cms-link,
.block-minicart .block-banners {
    margin: 15px 0 0;
    text-align: center;
}

// ==============================================
// Minicart Wrapper
// ==============================================

.minicart-wrapper {
    position: absolute;
    top: 27px;
    right: 20px;

    &:not(.active) > .block-minicart {
        display: none;
    }

    .block-minicart {
        @include menu;
        position: absolute;
        top: ($header-element-height + 11px);
        right: 0;
        width: $header-cart-offscreen-width;
        padding: 15px;

        .block-title {
            display: none;
        }
    }

    .action.showcart {
        white-space: nowrap;
        position: relative;
        display: inline-block;
        width: 106px;
        color: #fff;

        .bg-cart {
            min-height: $header-element-height;
            padding: 0 10px 0 (5px + 40px + 5px);
            background: $c-orange;

            &.empty {
                background: black(0.1);

                &:hover {
                    background: black(0.15);
                    color: #fff;
                }
            }

            &._block-content-loading .counter.qty {
                z-index: 10000;
            }
        }

        &:focus,
        &:active {
            color: #fff;
        }

        &.active {
            &::after {
                @include triangle(up, 8px, $c-white);
                top: ($header-element-height + 4px);
                left: 50%;
                z-index: $z-menu-arrow-fill;
                margin-left: -10px;
            }

            &::before {
                @include triangle(up, 8px, $c-border);
                top: ($header-element-height + 4px - 1px);
                left: 50%;
                z-index: $z-menu-arrow-stroke;
                margin-left: -10px;
            }
        }

        .text {
            color: $c-white;
        }

        .counter.qty {
            position: absolute;
            min-width: 26px;
            height: 26px;
            padding: 0 4px;
            border: 2px solid #fff;
            border-radius: (26px / 2);
            background: #f00;
            color: #fff;
            font-size: $font-size-xxxs;
            line-height: 22px;
            text-align: center;
            top: -13px;
            right: -13px;

            &.empty {
                display: none;
            }

            .loader > img {
                max-width: $minicart-quantity-height;
            }
        }

        .counter-label {
            @include lib-visually-hidden;
        }

        .loading-mask::before {
            top: calc(50% - 20px);
        }
    }

    .minicart-widgets {
        margin-top: $space-base;
    }
}

// ----------------------------------------------

@media (max-width: $bp-header-alpha - 1px) {
    .minicart-wrapper {
        position: static;
        float: right;
        margin-left: 15px;

        .action.showcart {
            width: auto;
            vertical-align: top;

            .counter.qty {
                position: relative;
                top: 21px;
                right: 5px;
            }

            .bg-cart {
                min-height: 67px;
                padding-left: 57px;

                &.empty {
                    background-color: transparent;

                    &:hover {
                        background: transparent;
                    }
                }
            }
        }

        .trigger-icon {
            left: 12px;
        }
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .minicart-wrapper {
        width: 67px;
        margin-left: 5px;

        .block-minicart {
            width: 290px;
        }

        .action.showcart .counter.qty {
            display: none;
        }
    }
}

@media (max-width: 349px) {
    .minicart-wrapper {
        width: 45px;
        margin-left: 0;

        .trigger-icon {
            left: 3px;
        }
    }
}

// ==============================================
// Module - Cart
// ==============================================

// Trigger Link - Elements

.trigger-icon {
    @include svg(cart);
    content: '';
    position: absolute;
    top: 50%;
    left: 8px;
    width: 40px;
    height: 40px;
    margin-top: -20px;
}

.trigger-label {
    padding-top: 15px;
    font-size: 20px;
    display: block;
    font-weight: 300;
    line-height: 1;
}

.cart-count {
    top: -13px;
    right: -13px;
}

@media (max-width: $bp-header-alpha - 1px) {
    .trigger-label {
        display: none;
    }
}

// ==============================================
// Minicart Items
// ==============================================

.minicart-items-wrapper {
    overflow-x: auto;
    border-left: 0;
    border-right: 0;
    width: 100%;
    max-height: 770px;

    @media (max-width: $bp-screen-m - 1px) {
        max-height: calc(100vh - 200px);
    }
}

.minicart-item {
    border-top: 1px solid $c-border;
}

.minicart-item-wrapper {
    @include lib-clearfix;
    position: relative;
    padding-right: 40px;
}

.minicart-item .product-item {
    border: none;
    margin: 20px 0;
}

.minicart-item .product-item-name {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.minicart-item .product-item .product-item-info {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding: 0;
}

.minicart-item .product-item .product-item-photo {
    width: 75px;
    flex: 0 0 75px;
    margin: 0 15px 0 0;
    vertical-align: top;
}

.minicart-item .product-item .product-item-list-right-column {
    width: 100%;
}

.minicart-item .product-item .product-quickview {
    background: none;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    right: auto;
    left: 15px;
    top: 9px;
    width: 30px;
    box-shadow: none;
    text-indent: -99999px;
}

.minicart-item .product-item .product-quickview span {
    text-indent: 0;
}

.minicart-item .product-attribute,
.minicart-item .product-inventory {
    display: none;
}

.minicart-item .product-item .price-box {
    margin: 10px 0 0;
    float: left;

    .price {
        font-size: 18px;
    }
}
.minicart-item .product-item-actions {
    float: left;
    margin-left: 50px;
    margin-top: 10px;
}

.minicart-legacy {
    .minicart-item_price {
        float: left;
    }

    .minicart-item_action {
        float: right;
        margin-left: $space-base;
    }

    .minicart-item_qty {
        position: relative;
        padding-right: $space-xxl;
    }

    @media (max-width: $bp-screen-m - 1px) {
        .minicart-item_action {
            display: none;
        }
    }

    .minicart-item-wrapper > .minicart-item_photo {
        position: absolute;
        left: 0;
        width: auto;
    }

    .minicart-item .minicart-item_name a {
        color: $c-link-base;

        &:hover {
            color: $c-link-hover;
        }
    }

    .minicart-item .minicart-item_details {
        padding-left: 88px;
    }

    .minicart-item .minicart-item_details .price {
        font-weight: $font-weight-bold;
    }

    .minicart-item .minicart-item_details .price-including-tax,
    .minicart-item .minicart-item_details .price-excluding-tax {
        margin: $space-xs 0 0;
    }

    .minicart-item .minicart-item_details .weee[data-label] {
        font-size: $font-size-xxs;
    }

    .minicart-item .input-text.qty {
        text-align: center;
        height: 25px;
        width: 40px;
    }

    .minicart-item .update-cart-item {
        background: transparent;
        border: none;
        @include lib-icon-font(
            $icon-update,
            $_icon-font-size: 28px,
            $_icon-font-line-height: 28px,
            $_icon-font-text-hide: true,
            $_icon-font-color: $c-library-gray-7,
            $_icon-font-color-hover: $c-library-gray-7,
            $_icon-font-color-active: $c-library-gray-7
        );
        position: absolute;
        right: 0;
        top: 0;
    }

    .minicart-item .action.delete {
        @include lib-icon-font(
            $icon-trash,
            $_icon-font-size: 28px,
            $_icon-font-line-height: 28px,
            $_icon-font-text-hide: true,
            $_icon-font-color: $c-library-gray-7,
            $_icon-font-color-hover: $c-library-gray-7,
            $_icon-font-color-active: $c-library-gray-7
        );
    }

    .minicart-item .action.delete[disabled='disabled'],
    .minicart-item .update-cart-item[disabled='disabled'] {
        &:before {
            opacity: 0.5;
        }
    }

    .minicart-item-wrapper {
        padding: 20px 0;
    }
}
