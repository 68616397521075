
@media (min-width: $bp-screen-s) {
    .modal--product-quickview {
        &.modal--slide {
            left: calc(100% - 600px);
        }
    }
}

.quickview-product-content {
    .product-item-info {
        @media (min-width: $bp-screen-s) {
            padding: 0 0 30px;
            display: flex;
        }

        .product-item-photo {
            margin-right: 10px;
            flex: 1 0 200px;
        }

        .price-box {
            margin-top: 20px;
        }
    }

    .product-details {
        margin: 0 -24px -24px;
    }

    .tabs.quickview-product-tabs {
        .tabs_nav-link:first-of-type {
            margin-left: 20px;
        }

        .tabs_nav-link {
            padding: 10px 15px;

            @media (max-width: $bp-screen-m) {
                &.active {
                    background: #FFFFFF;
                    color: #505050;

                    &::before {
                        content: '';
                        position: absolute;
                        top: -4px;
                        left: -1px; // Cover border
                        width: calc(1px + 100% + 1px); // Include borders
                        height: 4px;
                        background: $c-tab-border-top-active;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        bottom: -1px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background: $c-white;
                        z-index: 1;
                    }
                }
            }
        }

        .tabs_item {
            border-bottom: 0;
            border-left: 0;
            border-right: 0;
        }
    }
}
