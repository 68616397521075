// ==============================================
// Logo Wrapper
// ==============================================

.logo-wrapper {
    height: 100%;
    z-index: 5;
    display: inline-flex;
    align-items: center;
    margin-left: $layout-indent__width;
}

// ==============================================
// Logo
// ==============================================

.logo img {
    display: block;
}

.logo_image {
    width: 201px;
}

// ----------------------------------------------

@media (max-width: $bp-header-alpha - 1px) {
    .logo-wrapper {
        margin-left: 60px;
        vertical-align: top;
    }

    .logo_image {
        width: 133px;
    }
}

@media (max-width: $bp-screen-s - 1px) {
    .logo-wrapper {
        margin-left: 40px;
    }
}
