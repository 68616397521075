
.switch-button {
  background: #f3f5f6;
  border-radius: 8px;
  overflow: hidden;
  min-width: 240px;
  text-align: center;
  font-size: $font-size-xs;
  color: $c-link-active;
  position: relative;
  padding: 4px;
  padding-right: 50%;
  position: relative;

  &-sublabel {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    pointer-events: none;
  }

  &-checkbox {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;

    &:checked + .switch-button-label:before {
        animation: none;
        transform: translateX(100%);
        transition: transform 300ms linear;
    }

    & + .switch-button-label {
        position: relative;
        display: block;
        user-select: none;
        pointer-events: none;
        padding: 0;
        margin: 0;

        &:before {
            animation: none;
            content: "";
            background: #fff;
            height: 100%;
            width: 100%;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 8px;
            transform: translateX(0);
            transition: transform 300ms;
            opacity: 1;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
        }

        &:after {
            display: none;
        }
    }
  }

    .material-icons {
        font-size: $font-size-s;
        margin-right: 4px;
    }

    .switch-button-label-span {
        width: 100%;
        padding: 5px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
